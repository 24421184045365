<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept :isFirstValue="false" type="search" label="LBLDEPT" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field label="대상자" name="userId" v-model="searchParam.userId" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="특수검진 대상자 관리"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
      selection="multiple"
      rowKey="userId"
    >
      <template slot="suffixTitle">
        <font style="color:red;font-size:0.9em;font-weight:400;">화학물질의 특수건강검진 대상자는 삭제할 수 없습니다.&nbsp;&nbsp;</font>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrow" />
          <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRow" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'check-up-special-user',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        userId: '',
      },
      grid: {
        merge: [
          // { index: 0, colName: 'userId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width: 80px',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width: 100px',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            style: 'width: 100px',
            label: '대상자',
            align: 'center',
            sortable: true,
          },
          {
            name: 'typeName',
            field: 'typeName',
            style: 'width: 100px',
            label: '구분',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      editable: true,
      isSave: false,
      saveUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.hea.checkup.specialUser.list.url;
      this.saveUrl = transactionConfig.hea.checkup.specialUser.save.url;
      this.deleteUrl = transactionConfig.hea.checkup.specialUser.delete.url;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      this.popupOptions.title = "대상자 검색";
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { userId: item.userId }) === -1) {
            saveData.push({
              specialId: uid(),
              userId: item.userId,
            });
          }
        });

        this.$http.url = this.saveUrl;
        this.$http.type = 'PUT';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
      });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?', 
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
